import React, { useCallback, useEffect, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"

import Head from "../components/header/head"
import Layout from "../components/layout"
import {
  HEADER_IMAGE_ARTICLES,
  MAX_FEATURED_POSTS,
  HEADER_PAGE_NAME_ARTICLES,
  HEADER_TITLE_ARTICLES,
  HEADER_SUB_TITLE_ARTICLES,
  HEADER_COLOR_ARTICLES,
} from "../utils/constants"
import Slider from "../components/slider/slider"
import Heading from "../components/form/heading"
import {
  encodeURL,
  getSearchQueryFromUrl,
  getSearchResults,
  getSearchUrl,
  hasFilters,
  isBrowser,
  removeDuplicates,
} from "../utils/utils"
import SearchResult from "../components/search/search-result"
import type { Post } from "../common/post.model"
import type { SearchQueryOptions } from "../components/search/search-query-options.model"
import SliderHeader from "../components/slider/slider-header"
import { getData } from "../../.cache/page-ssr/index"

const ArticlesPage = ({ location }) => {
  const articlesData = useStaticQuery(graphql`
    query {
      allArticles: allContentfulPost(
        sort: { fields: publishedDate, order: DESC }
        filter: { postType: { typeId: { eq: "ARTICLE" } } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            featured
            tags
            originalAuthor {
              name
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
      featuredArticles: allContentfulPost(
        limit: 8
        sort: { fields: updatedAt, order: DESC }
        filter: { postType: { typeId: { eq: "ARTICLE" } }, featured: { eq: true } }
      ) {
        edges {
          node {
            title
            slug
            city
            publishedDate(formatString: "DD/MM/YYYY", locale: "fr")
            image {
              gatsbyImageData(height: 576, placeholder: BLURRED)
            }
            category {
              name
            }
            postType {
              typeId
            }
          }
        }
      }
    }
  `)
  // reorganize query data
  const articles: Post[] = articlesData.allArticles.edges.map((ad: any) => ad.node)
  const recentArticles = articles.slice(0, MAX_FEATURED_POSTS)
  const featuredArticles = articlesData.featuredArticles.edges.map((edge: any) => edge.node)
  // extract categories and authors lists
  const categories = removeDuplicates(articles.map(p => !!p.category && p.category.name))
  const originalAuthorsNames = removeDuplicates(articles.map(p => !!p.originalAuthor && p.originalAuthor.name))
  // state
  const [filteredResults, setFilteredResults] = useState<Post[]>([])
  const [isCurrentlySearching, setIsCurrentlySearching] = useState<boolean>(false)

  useEffect(() => {
    const query = getSearchQueryFromUrl(location.search)
    if (!query || (!query.all && !query.searchQuery)) {
      setFilteredResults([])
      setIsCurrentlySearching(false)
    } else if (query.all === "featured") {
      setFilteredResults(featuredArticles)
      setIsCurrentlySearching(true)
    } else if (query.all === "recent") {
      setFilteredResults(articles)
      setIsCurrentlySearching(true)
      if (isBrowser) {
        window.scrollTo(0, 0)
      }
    } else if (query.searchQuery) {
      setFilteredResults(getSearchResults(articles, query.searchQuery))
      setIsCurrentlySearching(true)
    }
  }, [location])

  const onSearch = (searchQuery: SearchQueryOptions) => {
    if (hasFilters(searchQuery)) {
      navigate(encodeURL(getSearchUrl(searchQuery)))
    } else {
      navigate("/articles")
    }
  }
  const handleAllRecent = () => {
    navigate("/articles?all=recent")
  }
  const handleAllFeatured = () => {
    navigate("?all=featured")
  }
  const onClearSearch = () => {
    navigate("/articles")
  }

  const headerSearchOptions = {
    categories: categories,
    originalAuthorsNames: originalAuthorsNames,
    authorsTitle: "PAR AUTEURS",
    onSearch: onSearch,
  }
  return (
    <Layout
      headerImg={HEADER_IMAGE_ARTICLES}
      headerSearch={headerSearchOptions}
      headerPageName={HEADER_PAGE_NAME_ARTICLES}
      headerTitle={HEADER_TITLE_ARTICLES}
      headerSubTitle={HEADER_SUB_TITLE_ARTICLES}
      headerColor={HEADER_COLOR_ARTICLES}
    >
      <Head
        title="Articles"
        description="Posts des universitaires, spécialistes, ou contributeurs cheminants francophones et internationaux."
      />
      <div className="py-8">
        {!isCurrentlySearching ? (
          <div>
            {featuredArticles?.length ? (
              <div>
                <SliderHeader
                  text="Les articles à la une :"
                  buttonText="Tous les articles à la une"
                  color="blue"
                  onSeeMoreClick={handleAllFeatured}
                ></SliderHeader>
                <div className="mt-4">
                  <Slider posts={featuredArticles.slice(0, MAX_FEATURED_POSTS)}></Slider>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="mt-12">
              <SliderHeader
                text="Les articles récents :"
                buttonText="Tous les articles"
                buttonSize="xlarge"
                color="blue"
                onSeeMoreClick={handleAllRecent}
              ></SliderHeader>
              <div className="mt-4">
                <Slider posts={recentArticles}></Slider>
              </div>
            </div>
          </div>
        ) : (
          <SearchResult
            posts={filteredResults}
            onClearSearch={onClearSearch}
            resultsTitle="Articles trouvés :"
          ></SearchResult>
        )}
      </div>
    </Layout>
  )
}
export default ArticlesPage
